<template>
  <div>
    <load-profile></load-profile>
    <main-tab />

    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right">
            <b-button
              variant="success"
              @click="addVehiclesToSchedule"
              v-if="selected.length > 0"
              class="mr-1"
            >
              {{ $t("button.addToSchecule") }}
            </b-button>
            <b-button
              :variant="selected.length <= 0 ? 'secondary' : 'warning'"
              @click="setReservePriceModal"
              :disabled="selected.length <= 0"
              v-if="$can('update', 'vehicle-price')"
            >
              {{ $t("button.reservePrice") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          @select="select"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="false"
          :viewable="true"
          :resource="resource"
          :route="route"
          primary-field="vinNumber"
          :is-select="isSelect"
          :selected="selected"
        >
          <template #head(increasement)>
            <div
              class="text-nowrap cursor-pointer"
              @click="isSelect = !isSelect"
            >
              <feather-icon
                icon="CheckSquareIcon"
                v-if="!isSelect"
              ></feather-icon>
              <feather-icon icon="XIcon" v-else></feather-icon>
            </div>
          </template>
          <template #cell(inspectedAt)="data">
            <span v-if="data.item.inspectedAt">
              {{ data.item.inspectedAt | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(reservePrice)="data">
            <span v-if="data.item.reservePrice">
              {{ data.item.reservePrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(buyNowPrice)="data">
            <span v-if="data.item.buyNowPrice">
              {{ data.item.buyNowPrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="!isSelect"
                v-b-tooltip.hover
                :title="$t('button.add')"
                :variant="!data.item.reservePrice ? 'secondary' : 'success'"
                :disabled="!data.item.reservePrice"
                pill
                size="sm"
                @click="addVehicleToSchedule(data.item)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                v-if="!isSelect"
                v-b-tooltip.hover
                :title="$t('button.remark')"
                variant="primary"
                pill
                size="sm"
                @click="openRemark(data.item)"
              >
                <feather-icon icon="MessageCircleIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
    <remark-modal ref="remarkModal" />
    <add-vehicle-to-schedule-modal
      @success="refresh"
      ref="addVehicleToScheduleModal"
    />
    <set-reserve-price-modal @success="refresh" ref="setReservePriceModal" />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import MainTab from "../MainTab.vue";
import RemarkModal from "../remark/RemarkModal.vue";
import AddVehicleToScheduleModal from "../addVehicleToScheduleModal/AddVehicleToScheduleModal.vue";
import SetReservePriceModal from "../setReservePriceModal/SetReservePriceModal.vue";

const VehicleRepository = Repository.get("vehicle");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    MainTab,
    RemarkModal,
    AddVehicleToScheduleModal,
    SetReservePriceModal,
  },
  watch: {
    perPage() {
      this.list();
    },
    isSelect(value) {
      if (!value) {
        this.selected = [];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      isSelect: false,
      selected: [],
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        vinNumber: this.$route.query.vinNumber || null,
        year: this.$route.query.year || [],
        vehicleBrandId: this.$route.query.vehicleBrandId || [],
        vehicleModelId: this.$route.query.vehicleModelId || [],
        zoneId: this.$route.query.zoneId || [],
        locationId: this.$route.query.locationId || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        vinNumber: this.$route.query.vinNumber || null,
        year: this.$route.query.year || [],
        vehicleBrandId: this.$route.query.vehicleBrandId || [],
        vehicleModelId: this.$route.query.vehicleModelId || [],
        zoneId: this.$route.query.zoneId || [],
        locationId: this.$route.query.locationId || [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    select(item) {
      const index = this.selected.findIndex((element) => {
        return element == item.id;
      });
      if (index == -1) {
        this.selected.push(item.id);
      } else {
        this.selected.splice(index, 1);
      }
    },
    addVehiclesToSchedule() {
      this.$refs.addVehicleToScheduleModal.show(this.selected);
    },
    addVehicleToSchedule(item) {
      this.$refs.addVehicleToScheduleModal.show([item.id]);
    },
    setReservePriceModal() {
      this.$refs.setReservePriceModal.show(this.selected);
    },
    openRemark(item) {
      this.$refs.remarkModal.show(item);
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    refresh() {
      this.isSelect = false;
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
        query: [
          {
            searchby: "status",
            searchoperator: "=",
            search: this.vehicleState.READY_TO_SCHEDULE,
          },
        ],
      };
      VehicleRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "auction-schedule";
    const route = "auction-schedule";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
