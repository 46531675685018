export default [
  {
    key: 'id',
    label: 'field.auctionSchedule',
    rules: 'required',
    type: 'single-selection',
    repository: 'schedule',
    selectionKey: 'id',
    selectionLabel: 'bidDate',
    cols: 12,
  },
]
