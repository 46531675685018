var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","cancel-title":_vm.$t('button.close'),"centered":"","no-close-on-backdrop":"","hide-footer":"","size":"xl"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('validation-observer',{ref:"reservePriceForm"},[_c('n-form-confirmation',{key:"updateForm",ref:"confirmForm",attrs:{"form":_vm.$refs.updateForm,"ok-variant":"success"},on:{"submit":_vm.save}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('n-button-loading',{attrs:{"type":"button","variant":"success","loading":_vm.loading},on:{"submit":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1),_c('n-table',{ref:"table",attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.loading,"current-page":_vm.query.page,"per-page":_vm.query.limit,"total":_vm.total,"resource":_vm.resource,"route":_vm.route},scopedSlots:_vm._u([{key:"cell(inspectedAt)",fn:function(data){return [(data.item.inspectedAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.inspectedAt))+" ")]):_c('span',[_vm._v("-")])]}},{key:"cell(reservePrice)",fn:function(data){return [_c('validation-provider',{attrs:{"vid":("price-" + (data.index)),"name":"field.price","rules":"decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-currency-input',{staticClass:"table-input",class:{
                'is-invalid': errors.length > 0,
                'bg-red': !data.item.reservePrice,
              },attrs:{"id":("price-" + (data.index)),"name":("price-" + (data.index)),"prefix":"$"},model:{value:(data.item.reservePrice),callback:function ($$v) {_vm.$set(data.item, "reservePrice", $$v)},expression:"data.item.reservePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}},{key:"cell(buyNowPrice)",fn:function(data){return [_c('validation-provider',{attrs:{"vid":("buy-now-price-" + (data.index)),"name":"field.price","rules":"decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('n-currency-input',{staticClass:"table-input",class:{
                'is-invalid': errors.length > 0,
              },attrs:{"id":("price-" + (data.index)),"name":("price-" + (data.index)),"prefix":"$"},model:{value:(data.item.buyNowPrice),callback:function ($$v) {_vm.$set(data.item, "buyNowPrice", $$v)},expression:"data.item.buyNowPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }