export default [
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    type: 'text',
    operator: '%',
  },
  {
    key: 'year',
    label: 'field.year',
    type: 'year-range-picker',
    cast: 'range',
    disableType: 'after',
    searchField: "year",
  },
  {
    key: 'vehicleBrandId',
    label: 'field.brand',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'vehicleBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'vehicleModelId',
    label: 'field.model',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'vehicleModel',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'locationId',
    label: 'field.location',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'zoneId',
    label: 'field.zone',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'zone',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
]