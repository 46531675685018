<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    hide-footer
    size="lg"
    v-model="isShow"
  >
    <n-table
      ref="table"
      :fields="fields"
      :items="items"
      :busy="loading"
      :current-page="query.page"
      :per-page="query.limit"
      :total="total"
      :resource="resource"
      :route="route"
    >
      <template #cell(createdBy)="data">
        <span v-if="data.item.createdBy">
          {{ data.item.createdBy.name }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(status)="data">
        <span
          :class="getTextClassByStatus(data.item.status)"
          v-if="data.item.status"
        >
          {{ $t(`status.${data.item.status}`) }}
        </span>
        <span v-else>-</span>
      </template>
    </n-table>
    <n-pagination
      ref="pagination"
      :total="total"
      :per-page="query.limit"
      :page="query.page"
      @change="list"
    ></n-pagination>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import NPagination from "@/components/NPagination";
import { getTextClassByStatus } from "@/libs/helper";

const VehicleCommentRepository = Repository.get("vehicleComment");

export default {
  components: {
    BModal,
    BRow,
    BCol,

    NTable,
    NPagination,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      items: [],
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      id: null,
      getTextClassByStatus,
    };
  },
  methods: {
    show(item) {
      this.id = item.vehicleId;
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      this.getData();

      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    getData() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const params = {
        ...this.query,
        searchFields: [],
        query: [
          {
            searchby: "vehicle_id",
            searchoperator: "=",
            search: this.id,
          },
          {
            searchby: "is_marketplace",
            searchoperator: "=",
            search: 1,
          },
        ],
      };
      VehicleCommentRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "auction-schedule";
    const route = "auction-schedule";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>